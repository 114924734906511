import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "uswds/dist/css/uswds.min.css"
import "uswds/dist/js/uswds.js"
import Header from "../components/header"
import Closures from "../components/closures"
import Footer from "../components/footer"
import Disclaimer from "../components/disclaimer"
import ScrollToTop from "../components/scrollToTop"


export default function NotFound() {
  const intl = useIntl();

  return (
    <div>
      <Header />
      <Closures />
      <main id="main-content">
        <section className="grid-container" id="explore">
            <div className="text-center margin-y-9 height-mobile">
                <h1>404 - Not Found </h1>
            </div>
        </section>
      </main>
      <Footer />
      <Disclaimer />
      <ScrollToTop />
    </div>
  )
}